<template>
  <PrimePage titleAlignment="left">
    <ion-row class="display-flex valign pad-twenty">
      <ion-col>
        <h3 class="view-title">Enter Event</h3>
      </ion-col>
      <ion-col></ion-col>
      <ion-col size="auto" class="margin-lr-ten">
        <button
          v-if="false"
          class="prime-button button-primary button-block"
          @click="goToNamedRoute('EventHistory')"
        >
          Event History
        </button>
      </ion-col>
    </ion-row>
    <!-- Step One -->
    <ion-card color="light" class="padding-death split-screen-card">
      <ion-row>
        <div v-if="isLoading" class="text-center pad-ten loading-overlay center-center">
          <ion-spinner name="lines" class="spinner-large" color="dark"></ion-spinner>
        </div>
        <ion-col class="column-stretch-wrap">
          <div class="column-stretch-header pad-twenty">
            <div v-if="!searchResults">
              Enter the ID of the patients you would like to add:
            </div>
            <div v-if="searchResults && searchResults.missingRecords">
              <ion-card
                color="warning"
                v-if="searchResults.missingRecords.length > 5"
                class="margin-lr-five"
              >
                <ion-row class="ion-align-items-center">
                  <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
                    <IconWarning />
                  </ion-col>
                  <ion-col>
                    <ion-row class="text-bold">
                      {{
                        searchResults.missingRecordsCount
                          ? searchResults.missingRecordsCount
                          : "More than five "
                      }}
                      MRNs could not be found
                    </ion-row>
                    <ion-row v-if="searchResults.url">
                      <span class="text-small link-dummy" @click="downloadReport(searchResults.url)"
                        >Download the full report</span
                      >
                    </ion-row>
                  </ion-col>
                </ion-row>
              </ion-card>
              <ion-card
                color="warning"
                v-for="(mrn, key) in searchResults.missingRecords"
                :key="key"
                class="margin-lr-five"
              >
                <ion-row class="ion-align-items-center">
                  <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
                    <IconWarning />
                  </ion-col>
                  <ion-col>
                    <ion-row class="text-bold">
                      {{ "ID: " + mrn }}
                    </ion-row>
                    <ion-row>
                      <span class="text-small">
                        {{ "Did not match a patient." }}
                      </span>
                    </ion-row>
                  </ion-col>
                </ion-row>
              </ion-card>
            </div>
          </div>
          <!-- {{ searchResults }} -->
          <div class="column-stretch-body add-patient-select-container pad-lr-twenty">
            <form
              v-if="!searchResults"
              @submit.prevent
              novalidate="true"
              class="margin-top-ten margin-bottom-ten full-height"
            >
              <ion-row class="full-height">
                <ion-col class="full-height">
                  <textarea-autosize
                    maxlength="200000"
                    placeholder="Paste comma-separated or line-separated MRNs here...."
                    class="form-control prime-message-input mrn-textarea"
                    ref="outboundMessageInput"
                    rows="5"
                    v-model="inputMrns"
                    @keydown.native.enter.exact.prevent
                    @keydown.native.enter.exact="newline"
                  />
                </ion-col>
              </ion-row>
            </form>
            <div v-if="searchResults" class="pad-top-five scroll-vert pad-lr-five">
              <div v-if="duplicateMrnsStagedPatients.length > 0" class="pad-bottom-ten">
                <ion-card
                  color="warning"
                  v-for="dupMrnPatient in duplicateMrnsStagedPatients"
                  :key="dupMrnPatient.beneficiaryId"
                  class="margin-lr-five"
                >
                  <ion-row class="ion-align-items-center">
                    <ion-col class="margin-lr-ten margin-right-twenty" size="auto">
                      <IconWarning />
                    </ion-col>
                    <ion-col>
                      <ion-row class="text-bold">
                        {{ dupMrnPatient.beneLastName + ", " + dupMrnPatient.beneFirstName }}
                      </ion-row>
                      <ion-row>
                        <span class="text-small">
                          {{
                            dupMrnPatient.duplicateMrn
                              ? `Duplicate ID: ${dupMrnPatient.duplicateMrn}`
                              : ""
                          }}
                        </span>
                      </ion-row>
                    </ion-col>
                  </ion-row>
                </ion-card>
              </div>

              <ion-card
                v-for="(patient, key) in searchResults.results"
                :key="key"
                color="white"
                class="prime-patient-card ion-margin-bottom"
              >
                <ion-row class="ion-align-items-center">
                  <div class="avatar" v-if="patient.beneFirstName && patient.beneLastName">
                    {{ patient.beneFirstName.charAt(0) + patient.beneLastName.charAt(0) }}
                  </div>
                  <div class="pad-lr-ten">
                    <div class="prime-patient-card-name text-bold">
                      {{ patient.beneLastName + ", " + patient.beneFirstName }}
                    </div>
                    <div
                      class="prime-patient-card-info text-muted text-small"
                      v-if="patient.mrn || patient.dob_str"
                    >
                      <strong v-if="patient.mrn">ID: </strong>
                      <span class="mrn-holder">{{ patient.mrn }}</span>
                      <div class="display-inline-block" v-if="patient.dob_str">
                        <strong>DOB: </strong> {{ patient.dob_str }}
                      </div>
                    </div>
                  </div>
                </ion-row>
              </ion-card>
            </div>
          </div>
          <div class="column-stretch-footer pad-lr-twenty pad-bottom-twenty">
            <div
              v-if="searchResults"
              class="text-center margin-bottom-ten margin-top-ten text-muted text-small"
            >
              <span class="text-bold"> {{ searchResults.missingRecordsCount }}</span> Error(s) -
              <span class="text-bold">{{ searchResults.results.length }}</span> Patient(s) Selected
            </div>
            <button
              v-if="searchResults && searchResults.results"
              @click="resetState"
              class="prime-button button-secondary button-block"
            >
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              <span>Clear</span>
            </button>
            <button
              v-else
              @click="processMrns"
              :class="{ 'button-pending': isProcessingMRNs }"
              :disabled="!inputMrns"
              class="prime-button button-primary button-block"
            >
              <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
              <span>Process MRNs</span>
            </button>
          </div>
        </ion-col>
        <ion-col>
          <div class="column-stretch-wrap full-height bg-white border-left">
            <div v-if="searchResults" class="column-stretch-header pad-lr-twenty margin-top-twenty">
              <div class="margin-bottom-ten">
                <span class="text-bold">Patients to Add Event For:</span>
              </div>
              <ion-card class="prime-patient-card ion-margin-bottom">
                <ion-row class="ion-align-items-center full-width">
                  <ion-col size="auto">
                    <div class="avatar avatar-number">
                      {{ searchResults.results.length }}
                    </div>
                  </ion-col>
                  <ion-col size="auto">
                    <span class="text-bold"
                      >{{ searchResults.results.length }} patient(s) selected</span
                    >
                  </ion-col>
                </ion-row>
              </ion-card>
            </div>
            <div class="column-stretch-body pad-lr-twenty pad-bottom-twenty">
              <div v-if="!searchResults" class="empty-container full-height text-muted">
                <IconPersonAdd class="prime-tasks-empty-icon icon-watermark icon-grey" />
                <h6>To get started, select patients.</h6>
              </div>
              <div v-else class="pad-top-twenty">
                <div v-if="selectedEvent">
                  <ion-row>
                    <ion-col align-self-center class="pad-top-twenty pad-bottom-twenty">
                      <a
                        class="cursor-pointer link-grey text-bold valign"
                        @click="resetEvent(selectedEvent.id)"
                      >
                        <ion-icon name="ios-arrow-back" class="icon-back"></ion-icon>
                        Back to Event Types
                      </a>
                    </ion-col>
                  </ion-row>
                  <div class="margin-top-five">
                    {{ selectedEvent ? "Type of event:" : "Select the type of event:" }}
                  </div>
                  <ion-card
                    color="white"
                    class="compact-shadow margin-death margin-top-ten cursor-pointer pad-twenty text-center"
                  >
                    <p class="text-bold margin-death">
                      {{ selectedEvent.eventName ? selectedEvent.eventName : "" }}
                    </p>
                  </ion-card>
                  <div
                    v-if="selectedEvent.primeEventType == 'medication_adherence'"
                    class="margin-top-twenty"
                  >
                    <h6>Initial Medication Concern:</h6>
                    <ion-row class="ion-align-items-center margin-left-twenty margin-top-twenty">
                      <ion-col size="auto">
                        <div class="checkbox-radio margin-right-ten">
                          <input
                            id="imc-bp"
                            type="radio"
                            value="Blood Pressure"
                            v-model="selectedMedConcern"
                          />
                          <label for="imc-bp"></label>
                        </div>
                      </ion-col>
                      <ion-col size="auto" class="valign">Blood Pressure</ion-col>
                    </ion-row>
                    <ion-row class="ion-align-items-center margin-left-twenty margin-top-ten">
                      <ion-col size="auto">
                        <div class="checkbox-radio margin-right-ten">
                          <input
                            id="imc-cholesterol"
                            type="radio"
                            value="Cholesterol"
                            v-model="selectedMedConcern"
                          />
                          <label for="imc-cholesterol"></label>
                        </div>
                      </ion-col>
                      <ion-col size="auto" class="valign">Cholesterol</ion-col>
                    </ion-row>
                    <ion-row class="ion-align-items-center margin-left-twenty margin-top-ten">
                      <ion-col size="auto">
                        <div class="checkbox-radio margin-right-ten">
                          <input
                            id="imc-diabetes"
                            type="radio"
                            value="Diabetes"
                            v-model="selectedMedConcern"
                          />
                          <label for="imc-diabetes"></label>
                        </div>
                      </ion-col>
                      <ion-col size="auto" class="valign">Diabetes</ion-col>
                    </ion-row>
                  </div>
                  <div
                    v-else-if="selectedEvent.primeEventType == 'visit_scheduling'"
                    class="margin-top-twenty"
                  >
                    <h6>Visit Scheduling Method:</h6>
                    <h5>How should a patient schedule their visit?</h5>
                    <ion-row class="ion-align-items-center margin-left-twenty margin-top-ten">
                      <ion-col size="auto">
                        <div class="checkbox-radio margin-right-ten">
                          <input
                            id="imc-false"
                            type="radio"
                            value="call"
                            v-model="selectedRVCommOption"
                          />
                          <label for="imc-false"></label>
                        </div>
                      </ion-col>
                      <ion-col size="auto" class="valign">Call Scheduling Phone Number</ion-col>
                    </ion-row>
                    <ion-row class="ion-align-items-center margin-left-twenty margin-top-ten">
                      <ion-col size="auto">
                        <div class="checkbox-radio margin-right-ten">
                          <input
                            id="imc-true"
                            type="radio"
                            value="link"
                            v-model="selectedRVCommOption"
                          />
                          <label for="imc-true"></label>
                        </div>
                      </ion-col>
                      <ion-col size="auto" class="valign">Use MyChart Link</ion-col>
                    </ion-row>
                  </div>
                </div>

                <div v-else>
                  <p class="margin-top-twenty margin-bottom-ten">Select the type of event:</p>
                  <div v-if="$can(I.VIEW_ADT_EVENTS)">
                    <p class="text-bold margin-top-twenty">Admissions and Discharges</p>
                    <!-- adtEvents -->
                    <ion-card
                      color="white"
                      v-for="event in adtEvents"
                      :key="event.id"
                      class="compact-shadow margin-death margin-top-ten cursor-pointer"
                      @click="selectEvent(event)"
                    >
                      <ion-row class="ion-align-items-center pad-five">
                        <ion-col>
                          <p class="text-bold margin-death">{{ event.eventName }}</p>
                        </ion-col>
                        <ion-col size="auto">
                          <IconCarrot class="icon-medium icon-grey valign" />
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                  <div v-if="$can(I.VIEW_AHP_EVENTS)">
                    <p class="text-bold margin-top-twenty">AdvantMed</p>
                    <ion-card
                      color="white"
                      v-for="event in ahpEvents"
                      :key="event.id"
                      class="compact-shadow margin-death margin-top-ten cursor-pointer"
                      @click="selectEvent(event)"
                    >
                      <ion-row class="ion-align-items-center pad-five">
                        <ion-col>
                          <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                        </ion-col>
                        <ion-col size="auto">
                          <IconCarrot class="icon-medium icon-grey valign" />
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                  <div v-if="$can(I.VIEW_ADVANCED_DIRECTIVE)">
                    <p class="text-bold margin-top-twenty">Advanced Directive</p>
                    <ion-card
                      color="white"
                      v-for="event in advanceddirective"
                      :key="event.id"
                      class="compact-shadow margin-death margin-top-ten cursor-pointer"
                      @click="selectEvent(event)"
                    >
                      <ion-row class="ion-align-items-center pad-five">
                        <ion-col>
                          <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                        </ion-col>
                        <ion-col size="auto">
                          <IconCarrot class="icon-medium icon-grey valign" />
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                  <div v-if="$can(I.VIEW_VISIT_COORDINATION)">
                    <p class="text-bold margin-top-twenty">Visit Coordination</p>
                    <!-- visitCoordination -->
                    <ion-card
                      color="white"
                      v-for="event in visitCoordination"
                      :key="event.id"
                      class="compact-shadow margin-death margin-top-ten cursor-pointer"
                      @click="selectEvent(event)"
                    >
                      <ion-row class="ion-align-items-center pad-five">
                        <ion-col>
                          <p class="text-bold margin-death">{{ event.eventName }}</p>
                        </ion-col>
                        <ion-col size="auto">
                          <IconCarrot class="icon-medium icon-grey valign" />
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                  <div>
                    <p class="text-bold margin-top-twenty">Education</p>
                    <ion-card
                      color="white"
                      v-for="event in education"
                      :key="event.id"
                      class="compact-shadow margin-death margin-top-ten cursor-pointer"
                      @click="selectEvent(event)"
                    >
                      <ion-row class="ion-align-items-center pad-five">
                        <ion-col>
                          <p class="text-bold margin-death">{{ event.eventName }}</p>
                        </ion-col>
                        <ion-col size="auto">
                          <IconCarrot class="icon-medium icon-grey valign" />
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                  <div v-if="$can(I.VIEW_CARE_GAP_EVENTS)">
                    <p class="text-bold margin-top-twenty">Open Alerts - Quality Considerations</p>
                    <ion-card
                      color="white"
                      v-for="event in careGaps"
                      :key="event.id"
                      class="compact-shadow margin-death margin-top-ten cursor-pointer"
                      @click="selectEvent(event)"
                    >
                      <ion-row class="ion-align-items-center pad-five">
                        <ion-col>
                          <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                        </ion-col>
                        <ion-col size="auto">
                          <IconCarrot class="icon-medium icon-grey valign" />
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                  <div v-if="$can(I.VIEW_QUALITY_CAMPAIGN_EVENTS)">
                    <p class="text-bold margin-top-twenty">Open Alerts - Quality Campaigns</p>
                    <ion-card
                      color="white"
                      v-for="event in qualityCampaigns"
                      :key="event.id"
                      class="compact-shadow margin-death margin-top-ten cursor-pointer"
                      @click="selectEvent(event)"
                    >
                      <ion-row class="ion-align-items-center pad-five">
                        <ion-col>
                          <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                        </ion-col>
                        <ion-col size="auto">
                          <IconCarrot class="icon-medium icon-grey valign" />
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                  <div v-if="$can(I.VIEW_LCMC_EVENTS)">
                    <p class="text-bold margin-top-twenty">LCMC</p>
                    <ion-card
                      color="white"
                      v-for="event in lcmcEvents"
                      :key="event.id"
                      class="compact-shadow margin-death margin-top-ten cursor-pointer"
                      @click="selectEvent(event)"
                    >
                      <ion-row class="ion-align-items-center pad-five">
                        <ion-col>
                          <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                        </ion-col>
                        <ion-col size="auto">
                          <IconCarrot class="icon-medium icon-grey valign" />
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                  <div v-if="$can(I.VIEW_BHAR_EVENTS)">
                    <p class="text-bold margin-top-twenty">BHAR</p>
                    <ion-card
                      color="white"
                      v-for="event in bharEvents"
                      :key="event.id"
                      class="compact-shadow margin-death margin-top-ten cursor-pointer"
                      @click="selectEvent(event)"
                    >
                      <ion-row class="ion-align-items-center pad-five">
                        <ion-col>
                          <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                        </ion-col>
                        <ion-col size="auto">
                          <IconCarrot class="icon-medium icon-grey valign" />
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                  <div v-if="$can(I.VIEW_HEALTHY_HEART_EVENTS)">
                    <p class="text-bold margin-top-twenty">Heart Health</p>
                    <ion-card
                      color="white"
                      v-for="event in heartHealth"
                      :key="event.id"
                      class="compact-shadow margin-death margin-top-ten cursor-pointer"
                      @click="selectEvent(event)"
                    >
                      <ion-row class="ion-align-items-center pad-five">
                        <ion-col>
                          <p class="text-bold margin-death">{{ event.eventTypeTitle }}</p>
                        </ion-col>
                        <ion-col size="auto">
                          <IconCarrot class="icon-medium icon-grey valign" />
                        </ion-col>
                      </ion-row>
                    </ion-card>
                  </div>
                </div>
              </div>
              <div class="column-stretch-footer pad-twenty">
                <button
                  @click="saveEvent(selectedEvent)"
                  :disabled="!selectedEvent"
                  :class="{
                    'button-pending': isLoading
                  }"
                  class="prime-button button-primary button-block"
                >
                  Add Event
                </button>
              </div>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-card>
  </PrimePage>
</template>

<script>
import IconCarrot from "@/components/Global/Icons/IconCarrot";
import IconPersonAdd from "@/components/Global/Icons/IconPersonAdd";
import IconWarning from "@/components/Global/Icons/IconWarning";
import PrimePage from "@/components/Global/PrimePage";
import { send as httpSend } from "@/services/Api";
import { addIcons } from "ionicons";
import { calendar } from "ionicons/icons";

addIcons({
  "md-calendar": calendar.md
});

export default {
  name: "Events",
  components: {
    IconWarning,
    PrimePage,
    IconPersonAdd,
    IconCarrot
  },
  data() {
    return {
      inputMrns: undefined,
      outputAccountIds: [],
      outputBeneIds: [],
      searchResults: undefined,
      isProcessingMRNs: false,
      isLoading: false,
      missingMRNs: [],
      missingRecordsCount: undefined,
      duplicateMrnsStagedPatients: [],
      selectedEvent: undefined,
      selectedMedConcern: undefined,
      selectedRVCommOption: "0",
      date: undefined,
      time: undefined,
      careGaps: [
        {
          id: 1,
          primeEventType: "medication_adherence",
          eventTypeTitle: "Medication Adherence",
          eventName: "Medication Adherence",
          beneficiaryId: undefined,
          accountId: undefined,
          medication_measure: undefined,
          event_time: undefined,
          program_id: 10
        }
      ],
      qualityCampaigns: [
        {
          id: 2,
          primeEventType: "diabetes",
          beneficiaryId: undefined,
          eventTypeTitle: "Diabetes",
          eventName: "Diabetes",
          event_type: "diabetes",
          program_id: 11
        },
        {
          id: 3,
          primeEventType: "blood_pressure",
          beneficiaryId: undefined,
          eventTypeTitle: "Blood Pressure",
          eventName: "Blood Pressure",
          event_type: "blood_pressure",
          program_id: 12
        },
        {
          id: 4,
          primeEventType: "statins",
          beneficiaryId: undefined,
          eventTypeTitle: "Statins",
          eventName: "Statins",
          event_type: "statins",
          program_id: 13
        },
        {
          id: 5,
          primeEventType: "bcs",
          beneficiaryId: undefined,
          eventTypeTitle: "Breast Cancer Screening",
          eventName: "Breast Cancer Screening",
          event_type: "bcs",
          program_id: 14
        },
        {
          id: 6,
          primeEventType: "ccs_0",
          beneficiaryId: undefined,
          eventTypeTitle: "Colorectal Cancer Screening - Initial Outreach",
          eventName: "Colorectal Cancer Screening - Initial Outreach",
          event_type: "ccs_initial_outreach",
          program_id: 15
        },
        {
          id: 7,
          primeEventType: "flu_vaccine_reminder",
          beneficiaryId: undefined,
          eventTypeTitle: "Vaccine Reminder",
          eventName: "Vaccine Reminder",
          event_type: "flu_vaccine_reminder",
          program_id: 3
        }
      ],
      lcmcEvents: [
        {
          id: 8,
          primeEventType: "ccs_lcmc",
          beneficiaryId: undefined,
          eventTypeTitle: "Colorectal Cancer Screening - LCMC",
          eventName: "Colorectal Cancer Screening - LCMC",
          event_type: "ccs_lcmc_outreach",
          program_id: 19
        }
      ],
      adtEvents: [
        {
          id: 9,
          primeEventType: "adt_a04",
          beneficiaryId: undefined,
          eventTypeTitle: "Admissions and Discharges",
          eventName: "Patient Registered (ADT_A04)",
          event_type: "Patient Registered at ED",
          facility_name: undefined,
          event_description: undefined,
          dx_description: undefined,
          event_time: undefined,
          program_id: 1
        },
        {
          id: 10,
          primeEventType: "adt_a01",
          beneficiaryId: undefined,
          eventTypeTitle: "Admissions and Discharges",
          eventName: "Patient Admit/Visit Notification (ADT_A01)",
          event_type: "Patient Admitted",
          facility_name: undefined,
          event_description: undefined,
          dx_description: undefined,
          event_time: undefined,
          program_id: 1
        },
        {
          id: 11,
          primeEventType: "adt_a03",
          beneficiaryId: undefined,
          eventTypeTitle: "Admissions and Discharges",
          eventName: "Patient Discharge/End Visit (ADT_A03)",
          event_type: "Patient Discharged",
          facility_name: undefined,
          event_description: undefined,
          dx_description: undefined,
          event_time: undefined,
          program_id: 1
        }
      ],
      visitCoordination: [
        {
          id: 12,
          primeEventType: "visit_scheduling",
          beneficiaryId: undefined,
          eventTypeTitle: "Visit Type",
          eventName: "Routine Visit Scheduling",
          event_type: "Routine Visit",
          visit_type: undefined,
          contact_method: undefined,
          program_id: 16
        },
        {
          id: 18,
          primeEventType: "post_discharge",
          beneficiaryId: undefined,
          eventTypeTitle: "Visit Type",
          eventName: "Post-Discharge Visit Scheduling",
          event_type: "Post-Discharge",
          program_id: 17
        }
      ],
      ahpEvents: [
        {
          id: 13,
          primeEventType: "healthy_house_call",
          beneficiaryId: undefined,
          eventTypeTitle: "Healthy House Call",
          eventName: "Healthy House Call",
          event_type: "Healthy House Call",
          program_id: 20
        }
      ],
      advanceddirective: [
        {
          id: 14,
          primeEventType: "advanced_directive",
          beneficiaryId: undefined,
          eventTypeTitle: "Advance Directives",
          eventName: "Advance Directives",
          event_type: "Advance Directives",
          program_id: 22
        }
      ],
      education: [
        {
          id: 15,
          primeEventType: "ed_utilization",
          beneficiaryId: undefined,
          eventTypeTitle: "Education",
          eventName: "ED Utilization",
          event_type: "ED Utilization",
          visit_type: undefined,
          contact_method: undefined,
          program_id: 21
        }
      ],
      bharEvents: [
        {
          id: 16,
          primeEventType: "bhar_post_discharge",
          beneficiaryId: undefined,
          eventTypeTitle: "Baptist Health Post Discharge Follow Up",
          eventName: "Baptist Health Post Discharge Follow Up",
          event_type: "Post Discharge",
          program_id: 22
        }
      ],
      heartHealth: [
        {
          id: 17,
          primeEventType: "heart_health",
          beneficiaryId: undefined,
          eventTypeTitle: "Heart Health",
          eventName: "Heart Health",
          event_type: "Heart Health",
          program_id: 18
        }
      ],

      arePatientsSelected: false,
      communicationPrefTimes: this.$languagePack.enrollmentForm.communicationPrefTimes
    };
  },
  computed: {
    inputMrnsCount() {
      if (!this.inputMrns) {
        return 0;
      }

      this.inputMrns.trim();
      this.inputMrns.replace(/\s+/g, "");
      return this.inputMrns.split(",").length;
    }
  },
  methods: {
    onTimeChange(evt) {
      this.time = evt.target.value;
    },
    resetEvent(evtId) {
      let cEvent = this.adtEvents.find(evt => evt.id === evtId);
      if (cEvent) {
        delete cEvent.accountId;
        delete cEvent.beneficiaryId;
        delete cEvent.event_time;
        delete cEvent.facility_name;
        delete cEvent.dx_description;
        delete cEvent.event_description;
      }
      this.date = undefined;
      this.selectedEvent = undefined;
    },
    goToNamedRoute(routeName) {
      if (this.$route.query.backId) {
        this.$router.push({
          name: routeName,
          params: { id: this.$route.query.backId }
        });
      } else {
        this.$router.push({
          name: routeName
        });
      }
    },
    downloadReport(url) {
      this.isGettingRecentConvos = true;
      const method = "get";
      const path = document.config.baseApiUrl + url;

      httpSend({ path, method, authToken: true })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "MyPCP-ID-Search-Report.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not download report. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
          this.isGettingRecentConvos = false;
        });
    },
    createTwoYuckyArrays() {
      this.searchResults.results.forEach(element => {
        this.outputAccountIds.push(element.accountId);
        this.outputBeneIds.push(element.beneficiaryId);
      });
    },
    selectEvent(event) {
      this.selectedEvent = { ...event };
    },
    resetState() {
      this.searchResults = undefined;
      this.inputMrns = undefined;
      this.selectedEvent = undefined;
      this.outputAccountIds = [];
      this.outputBeneIds = [];
    },
    cleanUpMrns(mrns) {
      return mrns
        .trim()
        .split("\n")
        .join(",")
        .replaceAll("'", "")
        .replaceAll('"', "")
        .replaceAll(/\s+/g, "");
    },
    UUID() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    },
    processMrns() {
      this.searchResults = undefined;
      this.isProcessingMRNs = true;
      const method = "post";
      const path = document.config.mrnSearchApi + "?inactive=true";

      this.inputMrns = this.cleanUpMrns(this.inputMrns);

      const payload = {
        page: 1,
        perPage: this.inputMrnsCount * 10,
        mrn: this.inputMrns,
        setMissingRecordsArrayCount: 10
      };

      httpSend({ path, method, payload, authToken: true })
        .then(result => {
          this.searchResults = result.data;

          this.duplicateMrnsStagedPatients = this.getDuplicateMrnPatients(
            this.searchResults && this.searchResults.results
          );
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Processing MRNs failed. Please try again later.",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isProcessingMRNs = false;
        });
    },
    saveEvent(event) {
      this.createTwoYuckyArrays();
      this.isLoading = true;
      event.beneficiaryId = this.outputBeneIds;
      event.accountId = this.outputAccountIds;
      event.event_time = this.date + " " + this.time ? this.time : "";
      this.selectedMedConcern ? (event.medication_measure = this.selectedMedConcern) : "";

      this.selectedRVCommOption ? (event.contact_method = this.selectedRVCommOption) : "call";

      const payload = event;
      const method = "post";
      const path = document.config.eventEntry;

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "An event was created for the selected patient(s).",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => {
              this.resetEvent(event.id);
              m.present();
            });
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not enter manual event. Please try again later.",
              message: error,
              duration: 10000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          this.resetState();
        });
    },

    getDuplicateMrnPatients(patients) {
      if (!patients || patients.length < 1) {
        return [];
      }

      const duplicateMrns = patients.reduce((a, e) => {
        a[e.mrn] = ++a[e.mrn] || 0;
        return a;
      }, {});

      patients.forEach(patient => {
        if (duplicateMrns[patient.mrn] > 0) {
          patient.duplicateMrn = patient.mrn;
        }
      });

      return patients.filter(p => p.duplicateMrn);
    }
  }
};
</script>

<style scoped>
ion-card.prime-patient-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  transition: all 0.2s ease-in-out;
}

ion-card.prime-patient-card .prime-patient-card-name {
  font-size: 16px;
  font-weight: 500;
}

ion-card.prime-patient-card .prime-patient-card-info {
  font-size: 12px;
  font-weight: 500;
  margin-top: 2px;
  color: var(--ion-color-medium-shade);
}

ion-card.prime-patient-card .prime-patient-card-name,
ion-card.prime-patient-card .prime-patient-card-info {
  width: 100%;
  max-width: 410px;
  overflow: hidden;
  text-overflow: ellipsis;
}

ion-card.prime-patient-card .prime-patient-card-icons {
  display: flex;
  /* position: absolute; */
  right: 20px;
}
.close-button {
  font-size: 30px;
  border-radius: 20px;
  color: #a0a0a0;
  padding: 1px;
}

.add-patient-select-container {
  display: flex;
  flex-direction: column;
}

.scroll-vert {
  overflow-y: auto;
}

.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}
.border-left {
  border-left: 2px solid #dadada;
}
.icon-watermark {
  width: 90px;
  fill: #c9ccd2;
}
.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

ion-card.split-screen-card {
  width: calc(100% - 60px);
  min-width: 800px;
  max-width: 1100px;
  align-self: center;
  flex-grow: 2;
}

.split-screen-card > ion-row,
.split-screen-card > ion-row > ion-col {
  height: 100%;
}
.input-icon {
  position: absolute;
  padding: 10px 10px 10px 10px;
}
.pad-date-input {
  padding-left: 35px;
}

.mrn-textarea {
  height: 98% !important;
  margin-bottom: 10px;
}

.avatar-number {
  background-color: rgb(191, 191, 191) !important;
}
.mrn-holder {
  display: inline-block;
  max-width: 370px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
.mrn-holder::after {
  content: " ";
  margin-right: 5px;
}
</style>
